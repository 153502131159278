import { template as template_2ce077639465445ea19b11a4aeeb4509 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { capitalize } from '@ember/string';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type VetrideTrip from '../../models/vetride-trip';
import type { PageMeta } from '../ui-pagination';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import UiTable from '../ui-table';
import { dateOnly, dateTime, timeOnly } from '@onwardcare/core/utils/date-format';
import { extractErrorMessage } from 'onward-portal/utils/error-handlers';
import GridActions from './request/grid-actions';
import RequestsFilterForm from './requests-filter';
import type { RequestsFilter } from './requests-filter';
import type { RequestsGridParams } from './requests-grid';
interface VaNotesSignature {
    Element: HTMLUListElement;
    Args: {
        notes: string | null;
    };
    Blocks: EmptyObject;
}
export class VaNotes extends Component<VaNotesSignature> {
    lines = this.args.notes?.split('\n');
    static{
        template_2ce077639465445ea19b11a4aeeb4509(`
    <ul>
      {{#each this.lines as |line|}}
        <li>{{line}}</li>
      {{/each}}
    </ul>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export interface NewRequestsSignature {
    Element: HTMLDivElement;
    Args: {
        vetrideTrips: VetrideTrip[];
        pageMeta: PageMeta;
        filters?: RequestsFilter;
        onParamsChanged?: (params: RequestsGridParams) => void;
        onRequestClosed?: () => void;
        isLoading: boolean;
    };
    Blocks: EmptyObject;
}
export default class NewRequestsComponent extends Component<NewRequestsSignature> {
    @service
    store: Store;
    @tracked
    closedErrorMessage: any | null = null;
    uppercase(str: string) {
        return capitalize(str);
    }
    dateTimeTz(date: Date | null | undefined, timeZone?: string) {
        return dateTime(date, {
            timeZone,
            nullText: 'N/A'
        });
    }
    dateOnlyTz(date: Date | null, timeZone?: string) {
        return dateOnly(date, {
            timeZone,
            nullText: 'N/A'
        });
    }
    timeOnlyTz(date: Date | null, timeZone?: string) {
        return timeOnly(date, {
            timeZone
        });
    }
    hasMatchingRides(request: VetrideTrip) {
        return request.hasMatchingRides === true;
    }
    @action
    filterChanged(filters: RequestsFilter) {
        const params: RequestsGridParams = {
            page: 1,
            filters
        };
        this.args.onParamsChanged?.(params);
    }
    @action
    pageChanged(page: number) {
        const params: RequestsGridParams = {
            page,
            filters: this.args.filters
        };
        this.args.onParamsChanged?.(params);
    }
    @action
    onRequestClosed() {
        this.args.onRequestClosed?.();
    }
    @action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    closedError(ex: any) {
        // TODO: This should be using a toast/flash message instead of static error.
        this.closedErrorMessage = extractErrorMessage(ex, 'Something went wrong trying to close the request.');
        // Makes sure that the page gets scrolled to the top so they see the error
        // since we are not using a toast/flash message.
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    static{
        template_2ce077639465445ea19b11a4aeeb4509(`
    <div class='flex flex-col gap-6'>
      <RequestsFilterForm
        @params={{@filters}}
        @onFilterChanged={{this.filterChanged}}
        @requestStatus='new'
      />

      {{#if this.closedErrorMessage}}
        <UiAlert data-test-id='error-message' @variant='danger'>
          {{this.closedErrorMessage}}
        </UiAlert>
      {{/if}}

      <UiTable
        @isLoading={{@isLoading}}
        @pageMeta={{@pageMeta}}
        @onPageChanged={{this.pageChanged}}
        as |Table|
      >
        <Table.Head as |Head|>
          <Head.Th>
            Appointment Time
          </Head.Th>
          <Head.Th>
            Trip ID
          </Head.Th>
          <Head.Th>
            Passenger
          </Head.Th>
          <Head.Th>
            Trip Details
          </Head.Th>
          <Head.Th>
            Trip Notes
          </Head.Th>
          <Head.Th>
            <span class='sr-only'>Edit</span>
          </Head.Th>
        </Table.Head>
        <Table.Body as |Body|>
          {{#each @vetrideTrips as |request|}}
            <Body.Tr
              data-test-id='request-row'
              data-has-matching-rides={{this.hasMatchingRides request}}
              class={{if (this.hasMatchingRides request) 'bg-yellow-50'}}
              as |Row|
            >
              <Row.Td data-test-id='appointment-time' @accent={{true}}>
                {{this.dateOnlyTz request.appointmentTime request.accountTz}}<br
                />
                {{this.timeOnlyTz request.appointmentTime request.accountTz}}
              </Row.Td>
              <Row.Td data-test-id='trip-id'>
                {{request.tripId}}
              </Row.Td>
              <Row.Td data-test-id='rider' @allowWrap={{true}}>
                {{request.passenger}}
              </Row.Td>
              <Row.Td data-test-id='trip-details'>
                <ul>
                  <li>
                    <span class='font-semibold'>Trip Type:</span>
                    <span>{{request.tripType}}</span>
                  </li>
                  <li>
                    <span class='font-semibold'>Est Pickup:</span>
                    <span>{{this.dateTimeTz
                        request.estimatedPickupTime
                        request.accountTz
                      }}</span>
                  </li>
                  <li>
                    <span class='font-semibold'>Earliest Dep:</span>
                    <span>{{this.dateTimeTz
                        request.earliestDepartureTime
                        request.accountTz
                      }}</span>
                  </li>
                  <li>
                    <span class='font-semibold'>Latest Arr:</span>
                    <span>{{this.dateTimeTz
                        request.latestArrivalTime
                        request.accountTz
                      }}</span>
                  </li>
                  <li>
                    <span class='font-semibold'>Appt Time:</span>
                    <span>{{this.dateTimeTz
                        request.appointmentTime
                        request.accountTz
                      }}</span>
                  </li>
                </ul>
              </Row.Td>
              <Row.Td data-test-id='trip-notes' @allowWrap={{true}}>
                <VaNotes @notes={{request.vaNotes}} />
              </Row.Td>
              <Row.Td @align='right'>
                <GridActions
                  @rideRequest={{request}}
                  @onError={{this.closedError}}
                  @onSaved={{this.onRequestClosed}}
                />
              </Row.Td>
            </Body.Tr>
          {{else}}
            <Body.Empty>
              There are no new requests.
            </Body.Empty>
          {{/each}}
        </Table.Body>
      </UiTable>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VaTools::NewRequests': typeof NewRequestsComponent;
    }
}
