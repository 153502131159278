import { template as template_20f04d1741ae4bef84022750cc15f86c } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface ChargesByRideStatusSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const ChargesByRideStatus: TOC<ChargesByRideStatusSignature> = template_20f04d1741ae4bef84022750cc15f86c(`
  <BasicColumnChart
    @format='currency'
    @stacking='normal'
    @modelName='charges-by-ride-status'
    @title='Ride Charges by Ride Status'
    @tooltipText='Total of Ride Charges by ride status type of Completed, No Show, or Canceled.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChargesByRideStatus;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::ChargesByRideStatus': typeof ChargesByRideStatus;
    }
}
