import { template as template_566c7e0680314a98b867d078e5d73204 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import type ViewableTransportType from '../../models/viewable-transport-type';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import { DEFAULT_ROUTE } from '../../utils/default-routes';
interface RideFormSignature {
    Element: HTMLDivElement;
    Args: {
        transportType: ViewableTransportType;
    };
    Blocks: EmptyObject;
}
const RideForm: TOC<RideFormSignature> = template_566c7e0680314a98b867d078e5d73204(`
  <div class='h-12 max-w-md'>
    <div class='flex items-center justify-between bg-white p-6'>
      <div class='flex items-center gap-2 font-semibold text-gray-900'>
        <span>Book New</span>
        <UiIcon @icon='arrow-right' @inheritSize={{true}} />
        <span>{{@transportType.fullName}}</span>
      </div>

      <UiBaseLink
        @route={{DEFAULT_ROUTE}}
        class='text-gray-400 hover:text-gray-500 focus:text-gray-500'
      >
        <UiIcon @icon='x-mark' />
      </UiBaseLink>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RideForm;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm': typeof RideForm;
    }
}
