import { template as template_89d247aeb54a4a1da6b6365825316405 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface AcceptanceRatesSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const AcceptanceRates: TOC<AcceptanceRatesSignature> = template_89d247aeb54a4a1da6b6365825316405(`
  <BasicColumnChart
    @format='percent'
    @modelName='acceptance-percentage'
    @title='Trip Acceptance Rate'
    @tooltipText='Percent of trips with a driver assigned at least 1 hour prior to the scheduled start time of trips requested in the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AcceptanceRates;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::AcceptanceRates': typeof AcceptanceRates;
    }
}
