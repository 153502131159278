import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../../services/onward-session';
import type Transition from '@ember/routing/transition';
import { captureMessage } from '../../utils/error-logging';

export default class VaToolsRoute extends Route {
  @service declare router: RouterService;
  @service('onward-session') declare session: SessionService;

  async beforeModel(transition: Transition) {
    if (this.session.isAdmin === false) {
      captureMessage('Access denied', {
        from: transition.from?.name,
        to: transition.to?.name,
      });

      return this.router.replaceWith('access-denied');
    }

    // If going to the root VA Tools route, redirect to the default page.
    if (transition.to?.name === 'authenticated.va-tools.index') {
      return this.router.replaceWith('authenticated.va-tools.ride-requests');
    }
  }
}
