import { modifier } from 'ember-modifier';

interface ApplyIdModifierSignature {
  Element: HTMLElement;
  Args: {
    Positional: [string | undefined];
  };
}

/**
 * This modifier applies the id to the element if it is passed, otherwise, it
 * does nothing.
 */
const applyId = modifier<ApplyIdModifierSignature>(
  (
    element: ApplyIdModifierSignature['Element'],
    positional: ApplyIdModifierSignature['Args']['Positional'],
  ) => {
    if (positional[0]) {
      element.id = positional[0];
    }
  },
);

export default applyId;
