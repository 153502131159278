import { template as template_1b5193805ced4d309c37653fb12f9558 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import type { RouteModel } from '@ember/routing/router-service';
import { or } from 'ember-truth-helpers';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
export interface UiMenuButtonSignature {
    Element: HTMLButtonElement | HTMLAnchorElement;
    Args: {
        close?: () => void;
        disabled?: boolean;
        download?: boolean;
        href?: string;
        route?: string;
        model?: RouteModel;
        models?: [RouteModel];
        query?: Record<string, unknown>;
        onClick?: () => void;
    };
    Blocks: {
        default: [];
    };
}
export default class UiMenuButtonComponent extends Component<UiMenuButtonSignature> {
    get classes() {
        const baseClassess = 'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900';
        const stateClasses = this.args.disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-100 focus:bg-gray-100 focus:outline-purple-700';
        return `${baseClassess} ${stateClasses}`;
    }
    @action
    handleClick() {
        this.args.onClick?.();
        this.args.close?.();
    }
    static{
        template_1b5193805ced4d309c37653fb12f9558(`
    {{#let
      'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 hover:bg-gray-100 focus:bg-gray-100 focus:outline-purple-700'
      as |classes|
    }}
      {{#if (or @href @route)}}
        <UiBaseLink
          @href={{@href}}
          @route={{@route}}
          @model={{@model}}
          @models={{@models}}
          @query={{@query}}
          @download={{@download}}
          class={{classes}}
          ...attributes
          {{on 'click' this.handleClick}}
        >
          {{yield}}
        </UiBaseLink>
      {{else}}
        <button
          type='button'
          class={{classes}}
          ...attributes
          {{on 'click' this.handleClick}}
        >
          {{yield}}
        </button>
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'ui-menu/button': typeof UiMenuButtonComponent;
        'UiMenu::Button': typeof UiMenuButtonComponent;
    }
}
