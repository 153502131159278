import { template as template_0b2bd6029f49472f8a2d3429f7400875 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import rideCompletionStatuses from '../utils/data/ride-completion-status';
import UiBadge, { type UiBadgeColor, type UiBadgeSize } from '@onwardcare/ui-components/components/ui-badge';
export interface RideCompletionStatusSignature {
    Element: HTMLSpanElement;
    Args: {
        size?: UiBadgeSize;
        status: string;
        textOnly?: boolean;
    };
    Blocks: EmptyObject;
}
export default class RideCompletionStatusComponent extends Component<RideCompletionStatusSignature> {
    get completionStatus() {
        return rideCompletionStatuses.find((status)=>this.args.status === status.id);
    }
    get statusText() {
        return this.completionStatus?.name ?? 'Unknown';
    }
    get color(): UiBadgeColor {
        switch(this.completionStatus?.id){
            case 'completed':
                return 'green';
            case 'cancelled':
            case 'noshow':
            case 'declined':
            case 'unfulfilled':
                return 'red';
            default:
                return 'gray';
        }
    }
    static{
        template_0b2bd6029f49472f8a2d3429f7400875(`
    {{#if @textOnly}}
      <span ...attributes>{{this.statusText}}</span>
    {{else}}
      <UiBadge @color={{this.color}} @size={{@size}} ...attributes>
        {{this.statusText}}
      </UiBadge>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        RideCompletionStatus: typeof RideCompletionStatusComponent;
    }
}
