import { template as template_03bdd1d07d134bd7b36dde0e6ee39d77 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import set from 'ember-set-helper/helpers/set';
import { eq } from 'ember-truth-helpers';
import type AccountModel from '../../models/account';
import type UserModel from '../../models/user';
import AccountDropdown from '../form-fields/account-dropdown';
import UserDropdown from '../form-fields/user-dropdown';
import ROLES from '../../utils/data/user-roles';
import UiForm from '@onwardcare/ui-components/components/ui-form';
export type ImpersonateType = 'generic' | 'specific';
export interface ImpersonateOptions {
    type: ImpersonateType;
    account: AccountModel | null;
    user: UserModel | null;
    roles: string[];
}
const DEFAULT_ROLES = [
    ROLES.CAN_ACCESS_BI
];
export interface ImpersonateFormSignature {
    Element: HTMLDivElement;
    Args: {
        onChange: (options: ImpersonateOptions) => void;
    };
    Blocks: EmptyObject;
}
export default class ImpersonateFormComponent extends Component<ImpersonateFormSignature> {
    @service
    store: Store;
    @tracked
    selectedType: ImpersonateType = 'specific';
    @tracked
    selectedAccount: AccountModel | null = null;
    @tracked
    selectedRoles: string[] = DEFAULT_ROLES;
    @tracked
    selectedUser: UserModel | null = null;
    get roles() {
        return [
            ROLES.CAN_ACCESS_BI,
            ROLES.DISPATCHER
        ];
    }
    get rolesDisplay() {
        const filteredRoles = this.roles.filter((role)=>this.selectedRoles.includes(role));
        return filteredRoles.join(', ');
    }
    get isSelectDisabled() {
        if (this.selectedType === 'generic') {
            return this.selectedAccount === null || this.selectedRoles.length === 0;
        } else if (this.selectedType === 'specific') {
            return this.selectedUser === null;
        }
        return false;
    }
    @action
    isTypeChecked(type: ImpersonateType) {
        return this.selectedType === type;
    }
    @action
    handleTypeChange(value: string) {
        this.selectedType = value as ImpersonateType;
        this.selectedUser = null;
        this.selectedRoles = DEFAULT_ROLES;
    }
    @action
    handleAccountChanged(account: AccountModel | null) {
        this.selectedAccount = account;
        this.selectedUser = null;
    }
    @action
    handleChange() {
        if (this.selectedType === 'generic') {
            this.args.onChange?.({
                type: this.selectedType,
                account: this.selectedAccount,
                user: null,
                roles: this.selectedRoles
            });
        } else if (this.selectedType === 'specific') {
            this.args.onChange?.({
                type: this.selectedType,
                account: this.selectedAccount,
                user: this.selectedUser,
                roles: []
            });
        }
    }
    static{
        template_03bdd1d07d134bd7b36dde0e6ee39d77(`
    <UiForm @onSubmit={{this.handleChange}} as |Form|>
      <fieldset class='hidden'>
        <legend>What kind of user do you want to impersonate?</legend>

        <Form.Group
          data-test-id='user-role'
          class='mt-2 flex items-center space-x-10'
          as |Group|
        >
          <Group.RadioButtons
            @name='impersonate'
            @groupValue={{this.selectedType}}
            @onChange={{this.handleTypeChange}}
            as |r|
          >
            <r.Radio data-test-id='generic' @value='generic'>
              Generic
            </r.Radio>
            <r.Radio data-test-id='specific' @value='specific'>
              Specific
            </r.Radio>
          </Group.RadioButtons>
        </Form.Group>
      </fieldset>

      {{#if (eq this.selectedType 'generic')}}
        <AccountDropdown
          @form={{Form}}
          @onChange={{set this 'selectedAccount'}}
          @selectedAccount={{this.selectedAccount}}
        />

        <Form.Group data-test-id='account' as |Group|>
          <Group.Label>Roles</Group.Label>
          <Group.Listbox
            @value={{this.selectedRoles}}
            @multiple={{true}}
            @onChange={{set this 'selectedRoles'}}
            as |listbox|
          >
            <listbox.Button @placeholder='Select roles'>
              {{this.rolesDisplay}}
            </listbox.Button>
            {{#each this.roles as |role|}}
              <listbox.Option @value={{role}}>
                {{role}}
              </listbox.Option>
            {{/each}}
          </Group.Listbox>
        </Form.Group>
      {{else}}
        <AccountDropdown
          @form={{Form}}
          @onChange={{this.handleAccountChanged}}
          @selectedAccount={{this.selectedAccount}}
        >
          <:helpText>
            This will allow you to filter the users based on account.
          </:helpText>
        </AccountDropdown>

        <UserDropdown
          @form={{Form}}
          @onChange={{set this 'selectedUser'}}
          @account={{this.selectedAccount}}
          @selectedUser={{this.selectedUser}}
        >
          <:labelText>
            User to impersonate
          </:labelText>
        </UserDropdown>
      {{/if}}

      <Form.Actions as |Actions|>
        <Actions.Submit
          data-test-id='select-button'
          @disabled={{this.isSelectDisabled}}
        >
          Select
        </Actions.Submit>
      </Form.Actions>
    </UiForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Impersonate::ImpersonateForm': typeof ImpersonateFormComponent;
    }
}
