import { template as template_c8cb075c49fb44cc81a7eb7d603dbf7b } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import set from 'ember-set-helper/helpers/set';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import UiValidationErrors, { type ValidationError } from '@onwardcare/ui-components/components/ui-validation-errors';
function emptyStringToNull(value: string | null) {
    return value === '' ? null : value;
}
export interface DateRange {
    startDate: string | null;
    endDate: string | null;
}
export interface CustomDateRangeSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        dateRange: DateRange;
        onChange: (dateRange: DateRange) => unknown;
    };
    Blocks: EmptyObject;
}
export default class CustomDateRangeComponent extends Component<CustomDateRangeSignature> {
    @tracked
    errors: ValidationError[] = [];
    @tracked
    _startDate: string = this.args.dateRange.startDate ?? '';
    @tracked
    _endDate: string = this.args.dateRange.endDate ?? '';
    get hasErrors() {
        return this.errors.length > 0;
    }
    validateRange(startDate: string, endDate: string) {
        if (startDate !== '' && endDate !== '' && startDate > endDate) {
            this.errors = [
                {
                    message: 'End Date must be on or after the Start Date'
                }
            ];
            return false;
        }
        this.errors = [];
        return true;
    }
    @action
    dateChanged() {
        const { _startDate, _endDate } = this;
        if (this.validateRange(_startDate, _endDate) === false) {
            return;
        }
        this.args.onChange({
            startDate: emptyStringToNull(_startDate),
            endDate: emptyStringToNull(_endDate)
        });
    }
    static{
        template_c8cb075c49fb44cc81a7eb7d603dbf7b(`
    <div class='flex flex-col gap-1' ...attributes>
      <div class='flex gap-2'>
        <@form.Group data-test-id='start-date' class='w-full' as |Group|>
          <Group.Label
            class='flex items-center gap-1'
            @hasErrors={{this.hasErrors}}
          >
            Start Date
          </Group.Label>
          <Group.Input
            type='date'
            @value={{this._startDate}}
            @hasErrors={{this.hasErrors}}
            @onBlur={{this.dateChanged}}
            @onChange={{set this '_startDate'}}
          />
        </@form.Group>
        <@form.Group data-test-id='end-date' class='w-full' as |Group|>
          <Group.Label
            class='flex items-center gap-1'
            @hasErrors={{this.hasErrors}}
          >
            End Date
          </Group.Label>
          <Group.Input
            type='date'
            @value={{this._endDate}}
            @hasErrors={{this.hasErrors}}
            @onBlur={{this.dateChanged}}
            @onChange={{set this '_endDate'}}
          />
        </@form.Group>
      </div>
      <UiValidationErrors @errors={{this.errors}} />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::CustomDateRange': typeof CustomDateRangeComponent;
    }
}
