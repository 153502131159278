import { template as template_53ecc57449be4ba9bbadbe33cd22b5c2 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import type RideModel from '../../../models/ride';
import type SettingsService from '../../../services/settings';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiMenu from '../../ui-menu';
export interface AdminLinksSignature {
    Element: HTMLDivElement;
    Args: {
        ride: RideModel;
    };
    Blocks: EmptyObject;
}
export default class AdminLinksComponent extends Component<AdminLinksSignature> {
    @service
    settings: SettingsService;
    get adminUrl() {
        return this.settings.getValue('ADMIN_CONSOLE_URL');
    }
    static{
        template_53ecc57449be4ba9bbadbe33cd22b5c2(`
    <div ...attributes>
      <UiMenu as |menu|>
        <menu.Trigger data-test-id='admin-links'>
          <UiButton @size='mini' @icon='bolt'>
            Admin Links
          </UiButton>
          <span class='sr-only'>Open menu</span>
        </menu.Trigger>

        <menu.Button
          data-test-id='admin-link-ride'
          @href='{{this.adminUrl}}/rides/{{@ride.id}}'
          target='_blank'
        >
          Ride
        </menu.Button>
        <menu.Button
          data-test-id='admin-link-rider'
          @href='{{this.adminUrl}}/riders/{{@ride.rider.id}}'
          target='_blank'
        >
          Rider
        </menu.Button>
        {{#if @ride.driver.id}}
          <menu.Button
            data-test-id='admin-link-driver'
            @href='{{this.adminUrl}}/drivers/{{@ride.driver.id}}'
            target='_blank'
          >
            Driver
          </menu.Button>
        {{/if}}
        <menu.Button
          data-test-id='admin-link-account'
          @href='{{this.adminUrl}}/accounts/{{@ride.account.id}}'
          target='_blank'
        >
          Account
        </menu.Button>
        {{#if @ride.account.parentAccount.id}}
          <menu.Button
            data-test-id='admin-link-parent-account'
            @href='{{this.adminUrl}}/parent_accounts/{{@ride.account.parentAccount.id}}'
            target='_blank'
          >
            Parent Account
          </menu.Button>
        {{/if}}
      </UiMenu>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Admin::Rides::AdminLinks': typeof AdminLinksComponent;
    }
}
