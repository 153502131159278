import { template as template_e970e645daf94a9ea989cd4a3972a467 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { add, addQuarters, endOfMonth, endOfQuarter, endOfWeek, endOfYear, startOfMonth, startOfQuarter, startOfWeek, startOfYear } from 'date-fns';
import { createReportingDateRangeItem, findDateGroupings, type DateGroupingOption, type ReportingDateRangeOption } from '../../utils/filters/date-range';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export const dateRanges: ReportingDateRangeOption[] = [
    createReportingDateRangeItem('last-2-full-weeks', 'Last 2 Full Weeks', startOfWeek(add(new Date(), {
        weeks: -2
    })), endOfWeek(add(new Date(), {
        weeks: -1
    })), findDateGroupings([
        'day'
    ])),
    createReportingDateRangeItem('last-4-full-weeks', 'Last 4 Full Weeks', startOfWeek(add(new Date(), {
        weeks: -4
    })), endOfWeek(add(new Date(), {
        weeks: -1
    })), findDateGroupings([
        'week'
    ])),
    createReportingDateRangeItem('this-month', 'This Month', startOfMonth(new Date()), endOfMonth(new Date()), findDateGroupings([
        'week'
    ])),
    createReportingDateRangeItem('last-month', 'Last Month', startOfMonth(add(new Date(), {
        months: -1
    })), endOfMonth(add(new Date(), {
        months: -1
    })), findDateGroupings([
        'week'
    ])),
    createReportingDateRangeItem('this-quarter', 'This Quarter', startOfQuarter(new Date()), endOfQuarter(new Date()), findDateGroupings([
        'week'
    ])),
    createReportingDateRangeItem('last-quarter', 'Last Quarter', startOfQuarter(addQuarters(new Date(), -1)), endOfQuarter(addQuarters(new Date(), -1)), findDateGroupings([
        'week'
    ])),
    createReportingDateRangeItem('year-to-date', 'Year to Date', startOfYear(new Date()), new Date(), findDateGroupings([
        'month',
        'quarter'
    ])),
    createReportingDateRangeItem('last-12-full-months', 'Last 12 Full Months', startOfMonth(add(new Date(), {
        months: -12
    })), endOfMonth(add(new Date(), {
        months: -1
    })), findDateGroupings([
        'month',
        'quarter'
    ])),
    createReportingDateRangeItem('last-12-months', 'Last 12 Months', startOfMonth(add(new Date(), {
        months: -11
    })), new Date(), findDateGroupings([
        'month',
        'quarter'
    ])),
    createReportingDateRangeItem('last-year', 'Last Year', startOfYear(add(new Date(), {
        years: -1
    })), endOfYear(add(new Date(), {
        years: -1
    })), findDateGroupings([
        'month',
        'quarter'
    ])),
    createReportingDateRangeItem('last-18-full-months', 'Last 18 Full Months', startOfMonth(add(new Date(), {
        months: -18
    })), endOfMonth(add(new Date(), {
        months: -1
    })), findDateGroupings([
        'quarter'
    ])),
    createReportingDateRangeItem('last-24-full-months', 'Last 24 Full Months', startOfMonth(add(new Date(), {
        months: -24
    })), endOfMonth(add(new Date(), {
        months: -1
    })), findDateGroupings([
        'quarter'
    ]))
];
export interface ReportingDateRangeFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        dateRange: ReportingDateRangeOption | null;
        dateGrouping: DateGroupingOption | null;
        onChange: (dateRange: ReportingDateRangeOption | null, dateGrouping: DateGroupingOption | null) => unknown;
    };
    Blocks: EmptyObject;
}
export default class ReportingDateRangeFilterComponent extends Component<ReportingDateRangeFilterSignature> {
    get showDateGrouping() {
        return (this.args.dateRange?.dateGroupingOptions && this.args.dateRange?.dateGroupingOptions.length > 1);
    }
    @action
    dateRangeSelected(dateRange: ReportingDateRangeOption) {
        // Select the first date grouping option from the date range.
        const dateGrouping = dateRange.dateGroupingOptions[0] ?? null;
        this.args.onChange(dateRange, dateGrouping);
    }
    @action
    dateGroupingSelected(dateGrouping: DateGroupingOption) {
        this.args.onChange(this.args.dateRange, dateGrouping);
    }
    static{
        template_e970e645daf94a9ea989cd4a3972a467(`
    <div class='flex flex-col gap-2'>
      <@form.Group data-test-id='date-range' as |Group|>
        <Group.Label class='flex items-center gap-1'>
          Date Range
        </Group.Label>
        <Group.Listbox
          @value={{@dateRange}}
          @onChange={{this.dateRangeSelected}}
          as |listbox|
        >
          <listbox.Button>
            {{@dateRange.label}}
          </listbox.Button>

          {{#each dateRanges as |dateRange|}}
            <listbox.Option @value={{dateRange}} as |option|>
              <span class='flex flex-col gap-1'>
                {{dateRange.label}}
                <span
                  class='{{if option.active "text-white" "text-gray-500"}}
                    text-xs'
                >
                  {{dateRange.description}}
                </span>
              </span>
            </listbox.Option>
          {{/each}}
        </Group.Listbox>
        <Group.HelpText>
          Uses the timezone of pickup location.
        </Group.HelpText>
      </@form.Group>

      {{#if this.showDateGrouping}}
        <@form.Group data-test-id='date-grouping' as |Group|>
          <Group.Label>Group By</Group.Label>
          <Group.Listbox
            @value={{@dateGrouping}}
            @onChange={{this.dateGroupingSelected}}
            as |listbox|
          >
            <listbox.Button>
              {{@dateGrouping.label}}
            </listbox.Button>

            {{#each @dateRange.dateGroupingOptions as |dateGrouping|}}
              <listbox.Option @value={{dateGrouping}}>
                {{dateGrouping.label}}
              </listbox.Option>
            {{/each}}
          </Group.Listbox>
        </@form.Group>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::ReportingDateRangeFilter': typeof ReportingDateRangeFilterComponent;
    }
}
