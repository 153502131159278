import { template as template_bf871a84dda642f1935ac05e481a8569 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
const MainArea: TOC<{
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}> = template_bf871a84dda642f1935ac05e481a8569(`
  <div class='px-4 pb-6 pt-4 sm:px-6 sm:pb-10 sm:pt-6 lg:px-8'>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default MainArea;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::MainArea': typeof MainArea;
    }
}
