import { template as template_73fe2f7a0650403385ff2b4ec8f6237d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BookNewMenu from './book-new-menu';
import type { MenuItem } from '../main-layout';
import MenuItemComponent from './menu-item';
import OnwardIcon from '../onward-icon';
export interface NavigationMenuSignature {
    Element: HTMLDivElement;
    Args: {
        menuItems: MenuItem[];
    };
    Blocks: EmptyObject;
}
const NavigationMenu: TOC<NavigationMenuSignature> = template_73fe2f7a0650403385ff2b4ec8f6237d(`
  <div
    class='flex h-full grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4'
  >
    <div class='flex h-16 shrink-0 items-center'>
      <OnwardIcon class='h-10 text-purple-900' />
      <span class='sr-only'>Onward</span>
    </div>
    <nav data-test-id='main-menu' class='flex flex-1 flex-col'>
      <BookNewMenu />

      <ul role='list' class='flex flex-1 flex-col gap-y-7'>
        <li>
          <ul role='list' class='-mx-2 space-y-1'>
            {{#each @menuItems as |item|}}
              <li>
                <MenuItemComponent @item={{item}} />
              </li>
            {{/each}}
          </ul>
        </li>
        {{! TODO: Add this back when we have settings. }}
        {{!--
        <li class="mt-auto">
          <MenuItemComponent
            @item={{hash
              name="Settings"
              type="button"
              icon="cog-6-tooth"
              route="authenticated.settings"
            }}
          />
        </li>
        --}}
      </ul>
    </nav>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default NavigationMenu;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::NavigationMenu': typeof NavigationMenu;
    }
}
