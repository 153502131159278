import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type SessionService from '../../../services/onward-session';
import type ViewableTransportType from '../../../models/viewable-transport-type';

export default class RidesNewRoute extends Route {
  @service('onward-session') declare session: SessionService;

  queryParams = {
    transportType: {
      refreshModel: true,
    },
  };

  model(params: { transportType: string }) {
    let transportType: ViewableTransportType | undefined;

    if (params.transportType === 'delivery') {
      // TODO: We probably need to do something differently here.
      transportType = {
        name: 'delivery',
        shortName: 'Delivery',
        fullName: 'Delivery',
      } as ViewableTransportType;
    } else {
      transportType = this.session.viewableTransportTypes.find(
        (type) => type.name === params.transportType,
      );
    }

    return {
      transportType,
    };
  }

  @action
  didTransition() {
    // Animate scrolling to the top of the page when params change.
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}
