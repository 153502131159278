import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { action } from '@ember/object';
import type UiListboxOptionComponent from './ui-listbox/option.gts';
import type UiListboxButtonComponent from './ui-listbox/button.gts';
import type UiListboxEmptyComponent from './ui-listbox/empty.gts';
import type { ValidationError } from './ui-validation-errors';

type State = 'default' | 'disabled' | 'error';

export interface UiListboxSignature {
  Element: HTMLDivElement;
  Args: {
    allowClear?: boolean;
    disabled?: boolean;
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    labelId?: string;
    multiple?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any | null) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any | null;
  };
  Blocks: {
    default: [
      {
        Button: WithBoundArgs<
          typeof UiListboxButtonComponent,
          'showClear' | 'value'
        >;
        Empty: WithBoundArgs<typeof UiListboxEmptyComponent, 'optionComponent'>;
        Option: WithBoundArgs<
          typeof UiListboxOptionComponent,
          'optionComponent'
        >;
      },
    ];
  };
}

const baseClasses =
  'transition-[outline] grid w-full h-9 grid-cols-1 rounded-md shadow-sm py-1.5 pl-3 pr-2 text-left outline outline-1 -outline-offset-1 focus:outline focus:outline-2 focus:-outline-offset-2 sm:text-sm/6';
const stateClasses: Record<State, string> = {
  default: 'bg-white text-gray-900 outline-gray-300 focus:outline-purple-700',
  disabled:
    'cursor-not-allowed bg-gray-200 text-gray-600 outline-gray-300 focus:outline-purple-700',
  error: 'bg-white text-gray-900 outline-red-400 focus:outline-red-500',
};

export default class UiListboxComponent extends Component<UiListboxSignature> {
  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  get baseClasses() {
    return baseClasses;
  }

  get stateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return stateClasses[state];
  }

  get showClear() {
    if (this.args.disabled) {
      return false;
    }

    if (this.args.multiple) {
      if (Array.isArray(this.args.value)) {
        return this.args.value.length > 0;
      }

      return this.args.value !== null;
    } else {
      return this.args.allowClear && this.args.value !== null;
    }
  }

  @action
  handleClear(event: Event) {
    // This prevents event propagation so that it doesn't open up the listbox
    // options when the clear button is clicked.
    event.stopPropagation();
    this.args.onChange?.(this.args.multiple ? [] : null);
  }
}
