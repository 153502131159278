import BaseAuthenticator from 'ember-simple-auth/authenticators/base';
import { service } from '@ember/service';
import { Promise } from 'rsvp';
import { isEmpty } from '@ember/utils';
import type Store from '@ember-data/store';
import { setUserContext } from 'onward-portal/utils/error-logging';

export type OnwardToken = {
  id: number;
  uid: string;
  client: string;
  expiry: string;
  accessToken: string;
};

export default class OnwardAuthenticator extends BaseAuthenticator {
  @service declare store: Store;

  get serverTokenEndpoint() {
    const adapter = this.store.adapterFor('application');
    return `${adapter.host}/custodian_auth/sign_in`;
  }

  authenticate(
    identification: string,
    password: string,
    scope: string[] = [],
    headers: Record<string, string> = {},
  ) {
    return new Promise((resolve, reject) => {
      fetch(this.serverTokenEndpoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: identification,
          password: password,
        }),
      })
        .then(async (resp) => {
          if (resp.ok) {
            const accessToken = resp.headers.get('access-token') || '';
            const client = resp.headers.get('client') || '';
            const uid = resp.headers.get('uid') || '';
            const expiry = resp.headers.get('expiry') || '';
            const { data } = await resp.json();
            const token: OnwardToken = {
              id: data.id,
              accessToken,
              client,
              uid,
              expiry,
            };

            return token;
          } else {
            const result = await resp.json();
            reject(result);
          }
        })
        .then((tokenData) => {
          resolve(tokenData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  restore(data: OnwardToken) {
    return this._validate(data) ? Promise.resolve(data) : Promise.reject();
  }

  _validate(data: OnwardToken) {
    return !isEmpty(data.accessToken) && !isEmpty(data.uid);
  }

  invalidate() {
    // This sets the user context for Sentry.
    setUserContext(null);
    return Promise.resolve();
  }
}
