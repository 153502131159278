import Service from '@ember/service';

export default class NavigationService extends Service {
  /**
   * This reloads the entire application. This is necessary to ensure all cache
   * is cleared and there's nothing left over from the previous user when doing
   * impersonation or context switching.
   */
  reloadApp() {
    window.location.href = '/';
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:navigation')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('navigation') declare altName: NavigationService;`.
declare module '@ember/service' {
  interface Registry {
    navigation: NavigationService;
  }
}
