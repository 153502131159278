import Service from '@ember/service';

export default class SessionStorageService extends Service {
  getItem(key: string) {
    return sessionStorage.getItem(key);
  }

  setItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:session-storage')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('session-storage') declare altName: SessionStorageService;`.
declare module '@ember/service' {
  interface Registry {
    'session-storage': SessionStorageService;
  }
}
