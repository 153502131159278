import { template as template_a78c5bb3157941ffbc4aeae6ab9f73d0 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface PaymentsByRideStatusSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const PaymentsByRideStatus: TOC<PaymentsByRideStatusSignature> = template_a78c5bb3157941ffbc4aeae6ab9f73d0(`
  <BasicColumnChart
    @format='currency'
    @stacking='normal'
    @modelName='payments-by-ride-status'
    @title='Driver Payouts by Ride Status'
    @tooltipText='Total of Ride Payouts to Drivers or Transportation Partners by No Show, Cancellation, and Completed in the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PaymentsByRideStatus;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::PaymentsByRideStatus': typeof PaymentsByRideStatus;
    }
}
