import { template as template_1ac4f0bb1b2c449f88b289725c5aab49 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface LateTransferCountsSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const LateTransferCounts: TOC<LateTransferCountsSignature> = template_1ac4f0bb1b2c449f88b289725c5aab49(`
  <BasicColumnChart
    @modelName='late-transfer-count'
    @stacking='normal'
    @title='Late Driver Reassignments'
    @tooltipText="Count of driver reassignments relative to the ride's requested start time."
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LateTransferCounts;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::LateTransferCounts': typeof LateTransferCounts;
    }
}
