import { template as template_4c4270b3e11b448699dc27400991044f } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import AccountModel from '../../models/account';
import { restartableTask, timeout } from 'ember-concurrency';
import type Store from '@ember-data/store';
// @ts-expect-error - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import perform from 'ember-concurrency/helpers/perform';
import { query } from 'ember-data-resources';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
const PAGE_SIZE = 50;
const DEBOUNCE_MS = 400;
export interface AccountDropdownSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        onChange: (account: AccountModel) => void;
        selectedAccount: AccountModel | null;
    };
    Blocks: {
        helpText: [];
    };
}
export default class AccountDropdownComponent extends Component<AccountDropdownSignature> {
    @service
    store: Store;
    @tracked
    term = '';
    get accountQueryParams() {
        const search = this.term ? this.term : this.args.selectedAccount?.name;
        return {
            filter: search ? {
                name: {
                    match: search
                }
            } : undefined,
            page: {
                size: PAGE_SIZE
            }
        };
    }
    accounts = query<AccountModel>(this, 'account', ()=>({
            ...this.accountQueryParams
        }));
    @action
    accountDisplayValue(value: AccountModel | string | null) {
        if (value instanceof AccountModel) {
            return value.name;
        }
        return value ?? '';
    }
    get loadingAccounts() {
        // If this is the first fetch, we want to show the loading state instead of
        // the empty state.
        const waitForFirstFetch = this.accounts.hasRan === false;
        return this.accounts.isLoading || waitForFirstFetch;
    }
    accountFilterChanged = restartableTask(async (term: string)=>{
        await timeout(DEBOUNCE_MS);
        // Skip fetching data if the term hasn't changed.
        if (term === this.term) {
            return;
        }
        this.term = term;
    });
    static{
        template_4c4270b3e11b448699dc27400991044f(`
    <@form.Group data-test-id='account' as |Group|>
      <Group.Label>Account</Group.Label>
      <Group.Combobox
        @placeholder='Search for an account...'
        @value={{@selectedAccount}}
        @allowClear={{true}}
        @displayValue={{this.accountDisplayValue}}
        @onChange={{@onChange}}
        @onFilterChange={{perform this.accountFilterChanged}}
        as |combobox|
      >
        {{#if this.loadingAccounts}}
          <combobox.Option @value='' @disabled={{true}}>
            Loading...
          </combobox.Option>
        {{else}}
          {{#each (sortBy 'name' this.accounts.records) as |account|}}
            <combobox.Option @value={{account}}>
              {{account.name}}
            </combobox.Option>
          {{else}}
            <combobox.Empty>
              No accounts found.
            </combobox.Empty>
          {{/each}}
        {{/if}}
      </Group.Combobox>

      {{#if (has-block 'helpText')}}
        <Group.HelpText>
          {{yield to='helpText'}}
        </Group.HelpText>
      {{/if}}
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'FormFields::AccountDropdown': typeof AccountDropdownComponent;
    }
}
