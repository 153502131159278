import { template as template_02679d09884d40e68f85f190944ffcfd } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface UpcomingChargeAndPaymentSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const ChartComponent: TOC<UpcomingChargeAndPaymentSignature> = template_02679d09884d40e68f85f190944ffcfd(`
  <BasicColumnChart
    @format='currency'
    @modelName='upcoming-charge-and-payment'
    @title='Scheduled Estimated Ride Charges and Payouts'
    @tooltipText='Sum of estimated charges and payouts for trips scheduled in the future.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChartComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::UpcomingChargeAndPayment': typeof ChartComponent;
    }
}
