import Controller from '@ember/controller';
import { service } from '@ember/service';
import type SessionService from '../services/onward-session';
import { DEFAULT_ROUTE } from '../utils/default-routes';

export default class NotFound extends Controller {
  @service('onward-session') declare session: SessionService;

  defaultRoute = DEFAULT_ROUTE;
}
