import { template as template_54d5624d429643359572667e94e29128 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
const PersonWalkingWithCaneIcon: TOC<{
    Element: SVGElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}> = template_54d5624d429643359572667e94e29128(`
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    fill='none'
    stroke='none'
    aria-hidden='true'
    ...attributes
  >
    <path
      fill='currentColor'
      d='M176 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8.4 32c-36.4 0-69.6 20.5-85.9 53.1L35.4 273.7c-7.9 15.8-1.5 35 14.3 42.9s35 1.5 42.9-14.3L128 231.6v43.2c0 17 6.7 33.3 18.7 45.3L224 397.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V390.6c0-12.7-5.1-24.9-14.1-33.9L224 306.7V213.3l70.4 93.9c10.6 14.1 30.7 17 44.8 6.4s17-30.7 6.4-44.8L268.8 166.4C250.7 142.2 222.2 128 192 128H167.6zM128.3 346.8L97 472.2c-4.3 17.1 6.1 34.5 23.3 38.8s34.5-6.1 38.8-23.3l22-88.2-52.8-52.8zM450.8 505.1c5 7.3 15 9.1 22.3 4s9.1-15 4-22.3L358.9 316.1c-2.8 3.8-6.1 7.3-10.1 10.3c-5 3.8-10.5 6.4-16.2 7.9L450.8 505.1z'
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PersonWalkingWithCaneIcon;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        PersonWalkingWithCaneIcon: typeof PersonWalkingWithCaneIcon;
    }
}
