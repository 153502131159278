import { template as template_43c37503b1ee42d3b6f30fa18739a8d3 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GMap from 'ember-google-maps/components/g-map';
import { eq } from 'ember-truth-helpers';
import config from 'onward-portal/config/environment';
export interface MapLocation {
    latitude: number;
    longitude: number;
}
const UiMap: TOC<{
    Args: {
        mapId: string;
        startLocation: MapLocation;
        endLocation: MapLocation;
    };
    Blocks: EmptyObject;
}> = template_43c37503b1ee42d3b6f30fa18739a8d3(`
  {{! For now, we don't want to render the map in tests. }}
  {{#unless (eq config.environment 'test')}}
    <GMap
      @center={{convertToLatLng @startLocation}}
      @mapId={{@mapId}}
      @zoom={{14}}
      @mapTypeControl={{false}}
      @streetViewControl={{false}}
      as |map|
    >
      <map.directions
        @origin={{convertToLatLng @startLocation}}
        @destination={{convertToLatLng @endLocation}}
        @travelMode='DRIVING'
        as |directions|
      >
        {{! We hide the markers so that we can make our own below. }}
        <directions.route @suppressMarkers={{true}} />
      </map.directions>

      <map.advancedMarker
        @lat={{@startLocation.latitude}}
        @lng={{@startLocation.longitude}}
      />
      <map.overlay
        @lat={{@startLocation.latitude}}
        @lng={{@startLocation.longitude}}
      >
        <OverlayCard>
          Pickup
        </OverlayCard>
      </map.overlay>

      <map.advancedMarker
        @lat={{@endLocation.latitude}}
        @lng={{@endLocation.longitude}}
      />
      <map.overlay
        @lat={{@endLocation.latitude}}
        @lng={{@endLocation.longitude}}
      >
        <OverlayCard>
          Dropoff
        </OverlayCard>
      </map.overlay>
    </GMap>
  {{/unless}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiMap;
const OverlayCard: TOC<{
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}> = template_43c37503b1ee42d3b6f30fa18739a8d3(`
  <div
    class='text-md translate-x-[-50%] translate-y-[-250%] rounded bg-white p-2 font-medium text-gray-800 shadow'
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
function convertToLatLng(location: MapLocation) {
    return {
        lat: location.latitude,
        lng: location.longitude
    };
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiMap: typeof UiMap;
    }
}
