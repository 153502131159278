import { template as template_d162d3e555474e34a743963e6ab332ac } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { restartableTask, timeout } from 'ember-concurrency';
import type Store from '@ember-data/store';
// @ts-expect-error - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import perform from 'ember-concurrency/helpers/perform';
import { query } from 'ember-data-resources';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import type AccountModel from '../../models/account';
import UserModel from '../../models/user';
const PAGE_SIZE = 50;
const DEBOUNCE_MS = 400;
export interface UserDropdownSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        onChange: (user: UserModel) => void;
        account: AccountModel | null;
        selectedUser: UserModel | null;
    };
    Blocks: {
        helpText: [];
        labelText: [];
    };
}
export default class UserDropdownComponent extends Component<UserDropdownSignature> {
    @service
    store: Store;
    @tracked
    term = '';
    get userQueryParams() {
        const search = this.term ? this.term : this.args.selectedUser?.displayName;
        return {
            account_id: this.args.account?.id ?? undefined,
            filter: search ? {
                name: {
                    match: search
                }
            } : undefined,
            page: {
                size: PAGE_SIZE
            }
        };
    }
    users = query<UserModel>(this, 'user', ()=>({
            ...this.userQueryParams
        }));
    @action
    userDisplayValue(value: UserModel | string | null) {
        if (value instanceof UserModel) {
            return value.displayName;
        }
        return value ?? '';
    }
    get loadingUsers() {
        // If this is the first fetch, we want to show the loading state instead of
        // the empty state.
        const waitForFirstFetch = this.users.hasRan === false;
        return this.users.isLoading || waitForFirstFetch;
    }
    userFilterChanged = restartableTask(async (term: string)=>{
        await timeout(DEBOUNCE_MS);
        // Skip fetching data if the term hasn't changed.
        if (term === this.term) {
            return;
        }
        this.term = term;
    });
    static{
        template_d162d3e555474e34a743963e6ab332ac(`
    <@form.Group data-test-id='user' as |Group|>
      <Group.Label>
        {{#if (has-block 'labelText')}}
          {{yield to='labelText'}}
        {{else}}
          User
        {{/if}}
      </Group.Label>
      <Group.Combobox
        @placeholder='Search for a user...'
        @value={{@selectedUser}}
        @allowClear={{true}}
        @displayValue={{this.userDisplayValue}}
        @onChange={{@onChange}}
        @onFilterChange={{perform this.userFilterChanged}}
        as |combobox|
      >
        {{#if this.loadingUsers}}
          <combobox.Option @value='' @disabled={{true}}>
            Loading...
          </combobox.Option>
        {{else}}
          {{#each (sortBy 'displayName' this.users.records) as |user|}}
            <combobox.Option @value={{user}} as |option|>
              <span class='flex flex-col gap-1'>
                <span data-test-id='label'>
                  {{user.displayName}}
                </span>
                <span
                  data-test-id='description'
                  class='{{if option.active "text-white" "text-gray-500"}}
                    text-xs'
                >
                  {{user.email}}
                </span>
              </span>
            </combobox.Option>
          {{else}}
            <combobox.Empty>
              No users found.
            </combobox.Empty>
          {{/each}}
        {{/if}}
      </Group.Combobox>

      {{#if (has-block 'helpText')}}
        <Group.HelpText>
          {{yield to='helpText'}}
        </Group.HelpText>
      {{/if}}
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'FormFields::UserDropdown': typeof UserDropdownComponent;
    }
}
