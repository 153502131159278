import { template as template_56a118eeccec4515a89c1fec6051b7d2 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import { add } from 'date-fns';
import { createDateRangeItem, type DateRangeOption, type FilterDateRange } from '../../utils/filters/date-range';
import DateRangeFilter from './date-range-filter';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export const dateRanges: DateRangeOption[] = [
    createDateRangeItem('today', 'Today', new Date(), new Date()),
    createDateRangeItem('upcoming', 'Today and Upcoming', new Date(), null),
    createDateRangeItem('yesterday', 'Yesterday', add(new Date(), {
        days: -1
    }), add(new Date(), {
        days: -1
    })),
    createDateRangeItem('yesterday-before', 'Yesterday and Before', null, add(new Date(), {
        days: -1
    })),
    createDateRangeItem('custom', 'Custom', null, null)
];
const RequestedStartTimeFilter: TOC<{
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        dateRange: FilterDateRange | null;
        onChange: (dateRange: FilterDateRange) => unknown;
    };
    Blocks: EmptyObject;
}> = template_56a118eeccec4515a89c1fec6051b7d2(`
  <DateRangeFilter
    data-test-id='requested-start-time'
    @form={{@form}}
    @dateRange={{@dateRange}}
    @label='Requested Start Time'
    @options={{dateRanges}}
    @onChange={{@onChange}}
  >
    <:helpText>
      Uses the timezone of account the ride was booked in.
    </:helpText>
  </DateRangeFilter>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RequestedStartTimeFilter;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::RequestedStartTimeFilter': typeof RequestedStartTimeFilter;
    }
}
