import { modifier } from 'ember-modifier';
import { createFocusTrap } from 'focus-trap';
import type { Options } from 'focus-trap';

export default modifier(function headlessuiFocusTrap(
  element: HTMLElement,
  params,
  { focusTrapOptions }: { focusTrapOptions?: Options } = {},
) {
  const trap = createFocusTrap(element, focusTrapOptions);
  trap.activate();

  return () => {
    trap.deactivate();
  };
});
