import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../../services/onward-session';
import { DEFAULT_ROUTE } from '../../utils/default-routes';
import type { ImpersonateOptions } from '../../components/impersonate/impersonate-form';

export default class ImpersonateController extends Controller {
  @service declare router: RouterService;
  @service('onward-session') declare session: SessionService;

  @action
  async handleChange(options: ImpersonateOptions) {
    if (options.type === 'specific' && options.user) {
      await this.session.impersonateUser(options.user);
    } else if (options.type === 'generic' && options.account) {
      await this.session.impersonate(options.account, options.roles);
    }

    this.router.replaceWith(DEFAULT_ROUTE);
  }
}
