import { template as template_84e33cd7acf94d59b8c9b521842bb367 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface PrivatePayCollectionPercentageSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const PrivatePayCollectionPercentage: TOC<PrivatePayCollectionPercentageSignature> = template_84e33cd7acf94d59b8c9b521842bb367(`
    <BasicColumnChart
      @format='percent'
      @modelName='private-pay-collection-percentage'
      @title='Private Pay Collection Rate'
      @tooltipText='Percent of trips with a start time in the date range where Financial Responsibility is "Private Pay" that are marked as Paid.'
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PrivatePayCollectionPercentage;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::PrivatePayCollectionPercentage': typeof PrivatePayCollectionPercentage;
    }
}
