import { template as template_4bb10120979c4cc08579c455ef87d704 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
const EarIcon: TOC<{
    Element: SVGElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}> = template_4bb10120979c4cc08579c455ef87d704(`
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    fill='none'
    stroke='none'
    aria-hidden='true'
    ...attributes
  >
    <path
      fill='currentColor'
      d='M502.6 54.6l-40 40c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l40-40c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3zm-320 320l-128 128c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3zM240 128c-57.6 0-105.1 43.6-111.3 99.5c-1.9 17.6-17.8 30.2-35.3 28.3s-30.2-17.8-28.3-35.3C74.8 132.5 149.4 64 240 64c97.2 0 176 78.8 176 176c0 46-17.7 87.9-46.6 119.3c-12 13-17.4 24.8-17.4 34.7V400c0 61.9-50.1 112-112 112c-17.7 0-32-14.3-32-32s14.3-32 32-32c26.5 0 48-21.5 48-48v-6.1c0-32.9 17.4-59.6 34.4-78c18.4-20 29.6-46.6 29.6-75.9c0-61.9-50.1-112-112-112zm0 80c-17.7 0-32 14.3-32 32c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-44.2 35.8-80 80-80s80 35.8 80 80c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-17.7-14.3-32-32-32z'
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EarIcon;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        EarIcon: typeof EarIcon;
    }
}
