import { template as template_a51fa3994900441fa4017d257b61c10e } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { and } from 'ember-truth-helpers';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: Figure out how to fix this.
import Transition from '@onwardcare/ember-headlessui/components/transition';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import type { MenuItem } from '../main-layout';
import type SessionService from '../../services/onward-session';
import ROLES from '../../utils/data/user-roles';
export interface MenuItemSignature {
    Element: HTMLAnchorElement;
    Args: {
        item: MenuItem;
        isChild?: boolean;
    };
    Blocks: EmptyObject;
}
export default class MenuItemComponent extends Component<MenuItemSignature> {
    @service
    router: RouterService;
    @service('onward-session')
    session: SessionService;
    @tracked
    _isActive: boolean | undefined = undefined;
    @tracked
    isExpanded = false;
    get buttonClasses() {
        return `group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold transition focus:outline-purple-700 ${this.isActive ? 'bg-purple-50 text-purple-800' : 'text-gray-700 hover:bg-purple-50 hover:text-purple-800'}`;
    }
    get itemClasses() {
        if (this.args.isChild) {
            return `flex items-center gap-x-2 rounded-md py-2 pr-2 pl-9 text-sm leading-6 transition focus:outline-purple-700 ${this.isActive ? 'bg-purple-50 text-purple-800' : 'text-gray-700 hover:bg-purple-50 hover:text-purple-800'}`;
        } else {
            return `group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold transition focus:outline-purple-700 ${this.isActive ? 'bg-purple-50 text-purple-800' : 'text-gray-700 hover:bg-purple-50 hover:text-purple-800'}`;
        }
    }
    get iconClasses() {
        return `transition shrink-0 ${this.isActive ? 'text-purple-800' : 'text-gray-400 group-hover:text-purple-800'}`;
    }
    get models() {
        if (this.args.item.model) {
            return [
                this.args.item.model
            ];
        } else if (this.args.item.models) {
            return this.args.item.models;
        }
        return [];
    }
    get isActive() {
        if (this._isActive === undefined) {
            return this.isActiveRoute();
        }
        return this._isActive;
    }
    set isActive(value: boolean) {
        this._isActive = value;
    }
    get isVisible() {
        if (this.args.item.internalOnly) {
            return this.session.isOnwardAssociate;
        } else if (this.args.item.requiredRoles) {
            return this.session.hasRoles(this.args.item.requiredRoles);
        }
        return true;
    }
    get isFolder() {
        return this.args.item.type === 'folder';
    }
    get showLightningBolt() {
        return (this.args.item.internalOnly || this.args.item.requiredRoles?.includes(ROLES.ADMIN));
    }
    get childIcon() {
        return this.showLightningBolt ? 'bolt' : this.args.item.icon;
    }
    constructor(owner: unknown, args: MenuItemSignature['Args']){
        super(owner, args);
        this.updateMenuState();
        this.router.on('routeDidChange', ()=>{
            this.updateMenuState();
        });
    }
    updateMenuState() {
        this._isActive = this.isActiveRoute();
        if (this.isActive && this.isFolder) {
            this.isExpanded = true;
        }
    }
    isActiveRoute() {
        if (this.args.item.route) {
            return this.models.length > 0 ? this.router.isActive(this.args.item.route, this.models) : this.router.isActive(this.args.item.route);
        }
        return false;
    }
    @action
    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }
    static{
        template_a51fa3994900441fa4017d257b61c10e(`
    {{#if (and this.isFolder this.isVisible)}}
      <button
        data-test-id={{@item.testId}}
        type='button'
        class={{this.buttonClasses}}
        {{on 'click' this.toggleExpanded}}
      >

        {{#if @item.icon}}
          <UiIcon @icon={{@item.icon}} class={{this.iconClasses}} />
        {{/if}}

        <span class='grow text-left'>
          {{@item.name}}
        </span>

        <UiIcon
          @icon='chevron-right'
          @type='mini'
          class='ml-auto shrink-0 transition
            {{if this.isExpanded "rotate-90" "rotate-0"}}'
        />
      </button>

      <Transition
        @show={{this.isExpanded}}
        @enter='transition duration-100 ease-out'
        @enterFrom='transform scale-95 opacity-0'
        @enterTo='transform scale-100 opacity-100'
        @leave='transition duration-75 ease-out'
        @leaveFrom='transform scale-100 opacity-100'
        @leaveTo='transform scale-95 opacity-0'
      >
        <ul class='mt-1 px-2'>
          {{#each @item.children as |child|}}
            <li>
              <MenuItemComponent @item={{child}} @isChild={{true}} />
            </li>
          {{/each}}
        </ul>
      </Transition>
    {{else if this.isVisible}}
      {{#if @item.route}}
        <LinkTo
          data-test-id={{@item.testId}}
          @route={{@item.route}}
          class={{this.itemClasses}}
        >
          {{#if this.childIcon}}
            <UiIcon
              @icon={{this.childIcon}}
              @type={{if @isChild 'micro' 'outline'}}
              class={{this.iconClasses}}
            />
          {{/if}}

          {{@item.name}}
        </LinkTo>
      {{else if @item.href}}
        <a
          data-test-id={{@item.testId}}
          href={{@item.href}}
          class={{this.itemClasses}}
        >
          {{#if this.childIcon}}
            <UiIcon
              @icon={{this.childIcon}}
              @type={{if @isChild 'micro' 'outline'}}
              class={{this.iconClasses}}
            />
          {{/if}}

          {{@item.name}}
        </a>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::MenuItem': typeof MenuItemComponent;
    }
}
