import { template as template_7af77a11a3b44222871f1deaee25bfe4 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
const DEFAULT_STAR_COUNT = 5;
export interface UiStarRatingSignature {
    Element: HTMLDivElement;
    Args: {
        size?: 'default' | 'small';
        starCount?: number;
        rating: number | null;
    };
    Blocks: EmptyObject;
}
export default class UiStarRatingComponent extends Component<UiStarRatingSignature> {
    get starCount() {
        const { starCount } = this.args;
        return starCount !== undefined && starCount > 0 ? starCount : DEFAULT_STAR_COUNT;
    }
    get stars() {
        const { rating } = this.args;
        if (rating === null) {
            return [];
        }
        const stars = new Array(this.starCount).fill(0);
        return stars.map((_, index)=>{
            return {
                id: index + 1,
                fill: index < rating
            };
        });
    }
    get iconSizeClass() {
        return this.args.size === 'small' ? 'w-4 h-4' : '';
    }
    get textSizeClass() {
        return this.args.size === 'small' ? 'text-xs' : 'text-sm';
    }
    static{
        template_7af77a11a3b44222871f1deaee25bfe4(`
    {{#if this.stars}}
      <div class='flex items-center gap-2'>
        <div data-test-id='star-rating' class='flex items-center gap-1'>
          {{#each this.stars as |star|}}
            <UiIcon
              data-test-id='star'
              data-test-type={{if star.fill 'solid' 'outline'}}
              @icon='star'
              @type={{if star.fill 'solid' 'outline'}}
              class='text-purple-800 {{this.iconSizeClass}}'
            />
          {{/each}}
        </div>
        {{! Need the 2px top margin to perfectly align the text with the icons. }}
        <span
          data-test-id='star-rating-text'
          class='mt-[2px] inline-block text-gray-500 {{this.textSizeClass}}'
        >
          ({{@rating}}
          of
          {{this.starCount}})
        </span>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiStarRating: typeof UiStarRatingComponent;
    }
}
