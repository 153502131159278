import { template as template_4912365115c84004af6735695bbe7947 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface VolumeByBookedInAdvanceSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const ChartComponent: TOC<VolumeByBookedInAdvanceSignature> = template_4912365115c84004af6735695bbe7947(`
  <BasicColumnChart
    @modelName='volume-by-booked-in-advance'
    @stacking='normal'
    @title='Volume Booked in Advance'
    @tooltipText='Volume of trips segmented by lead time. Last minute trips are booked < 2 hours prior to start time.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChartComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::VolumeByBookedInAdvance': typeof ChartComponent;
    }
}
