import { template as template_8cfce49c2a6a417cafc9cb59e295d850 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
export interface OnwardIconSignature {
    Element: SVGElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const OnwardIcon: TOC<OnwardIconSignature> = template_8cfce49c2a6a417cafc9cb59e295d850(`
  <svg
    fill='none'
    viewBox='0 0 159.53 159.53'
    stroke-width='6'
    stroke='currentColor'
    aria-hidden='true'
    ...attributes
  >
    <circle cx='79.77' cy='79.77' r='76.77' />
    <path
      fill='currentColor'
      stroke='none'
      d='M79.77,58.81A10.68,10.68,0,1,0,90.45,69.49,10.69,10.69,0,0,0,79.77,58.81Z'
    />
    <path
      fill='currentColor'
      stroke='none'
      d='M79.77,92.5A22.52,22.52,0,1,1,102.28,70,22.54,22.54,0,0,1,79.77,92.5Zm24.39-47a34.51,34.51,0,0,0-58.9,24.4c0,6.77,1.75,14,5.21,21.53A94.22,94.22,0,0,0,61.68,109.5,127.72,127.72,0,0,0,77.9,126.92a2.87,2.87,0,0,0,3.73,0A127,127,0,0,0,97.85,109.5a94.22,94.22,0,0,0,11.21-18.11c3.46-7.52,5.21-14.76,5.21-21.53a34.26,34.26,0,0,0-10.11-24.4Z'
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OnwardIcon;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        OnwardIcon: typeof OnwardIcon;
    }
}
