import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { captureMessage } from '../../utils/error-logging';
import type FeatureService from '../../services/feature';

export default class RidesRoute extends Route {
  @service declare feature: FeatureService;
  @service declare router: RouterService;

  async beforeModel(transition: Transition) {
    if (this.feature.isEnabled('portal_book_new') === false) {
      captureMessage('Access denied', {
        from: transition.from?.name,
        to: transition.to?.name,
      });

      return this.router.replaceWith('access-denied');
    }
  }
}
