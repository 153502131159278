import { template as template_61bacdb0247149b59f3527bc1cbe74ed } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import set from 'ember-set-helper/helpers/set';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Need to create the types for this.
import EmberPopover from 'ember-tooltips/components/ember-popover';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Need to create the types for this.
import EmberTooltip from 'ember-tooltips/components/ember-tooltip';
import UiButton from '@onwardcare/ui-components/components/ui-button';
export interface TooltipButtonSignature {
    Element: HTMLButtonElement;
    Args: {
        popover?: boolean;
    };
    Blocks: {
        button: [];
        tooltip: [];
    };
}
export default class TooltipButtonComponent extends Component<TooltipButtonSignature> {
    @tracked
    showTooltip = false;
    get popperOptions() {
        return {
            // This option prevents the tooltip from rendering off screen.
            modifiers: {
                preventOverflow: {
                    escapeWithReference: false
                }
            }
        };
    }
    static{
        template_61bacdb0247149b59f3527bc1cbe74ed(`
    <button
      type='button'
      class='focus:outline-purple-700'
      {{on 'click' (set this 'showTooltip' true)}}
      ...attributes
    >
      {{yield to='button'}}

      {{#if @popover}}
        <EmberPopover
          @arrowClass='hidden'
          @tooltipClass='font-normal text-left shadow-md p-4 sm:p-6'
          @spacing={{8}}
          @side='left'
          @isShown={{this.showTooltip}}
          @delayOnChange={{false}}
          @effect='fade'
          @event='none'
          @popperOptions={{this.popperOptions}}
        >
          {{yield to='tooltip'}}

          <div class='mt-4'>
            <UiButton @onClick={{set this 'showTooltip' false}}>
              Close
            </UiButton>
          </div>
        </EmberPopover>
      {{else}}
        <EmberTooltip
          @arrowClass='!border-t-gray-800'
          @tooltipClass='font-normal text-left bg-gray-800 max-w-xs text-sm leading-normal'
          @spacing={{8}}
          @event='click'
          @popperOptions={{this.popperOptions}}
        >
          {{yield to='tooltip'}}
        </EmberTooltip>
      {{/if}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        TooltipButton: typeof TooltipButtonComponent;
    }
}
