import { template as template_950cad318b1b4fb3bc497d0585501f05 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type VetrideTripModel from 'onward-portal/models/vetride-trip';
import type SettingsService from '../../../services/settings';
import { and, notEq } from 'ember-truth-helpers';
import { restartableTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import set from 'ember-set-helper/helpers/set';
import LinkRideForm from './link-ride-form';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
import UiConfirmation from '../../ui-confirmation';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiMenu from '../../ui-menu';
interface GridActionsSignature {
    Element: HTMLDivElement | HTMLAnchorElement;
    Args: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => unknown;
        onSaved: () => unknown;
        rideRequest: VetrideTripModel;
    };
    Blocks: EmptyObject;
}
export default class GridActionsComponent extends Component<GridActionsSignature> {
    @service
    settings: SettingsService;
    @tracked
    isConfirmationOpen = false;
    @tracked
    isLinkRideOpen = false;
    get adminConsoleUrl() {
        return this.settings.getValue('ADMIN_CONSOLE_URL');
    }
    get dashboardUrl() {
        return this.settings.getValue('DASHBOARD_URL');
    }
    get showDetailsLinkOnly() {
        return (this.args.rideRequest.requestStatus === 'closed' && this.args.rideRequest.rideId === null);
    }
    closeRequest = restartableTask(async (close: () => unknown)=>{
        const rideRequest = this.args.rideRequest;
        async function closeConfirmation() {
            close();
            // The fade duration for the modal is 300.
            await timeout(350);
        }
        try {
            rideRequest.requestStatus = 'closed';
            await rideRequest.save();
            await closeConfirmation();
            this.args.onSaved?.();
        } catch (ex: any) /* eslint-disable-line @typescript-eslint/no-explicit-any */ {
            rideRequest.rollbackAttributes();
            await closeConfirmation();
            this.args.onError?.(ex);
        }
    });
    @action
    requestSaved() {
        this.isLinkRideOpen = false;
        this.args.onSaved?.();
    }
    static{
        template_950cad318b1b4fb3bc497d0585501f05(`
    {{#if this.showDetailsLinkOnly}}
      <UiBaseLink
        data-test-id='details-link'
        @route='authenticated.va-tools.ride-requests.details'
        @model={{@rideRequest.id}}
        class='h-6 rounded-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-50 focus:outline-purple-700'
      >
        Details
      </UiBaseLink>
    {{else}}
      <div class='inline-flex justify-end'>
        <UiBaseLink
          data-test-id='details-link'
          @route='authenticated.va-tools.ride-requests.details'
          @model={{@rideRequest.id}}
          class='h-6 rounded-l-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-50 focus:outline-purple-700'
        >
          Details
        </UiBaseLink>

        <UiMenu as |menu|>
          <menu.Trigger
            data-test-id='grid-actions'
            class='-ml-px h-6 rounded-r-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-50 focus:outline-purple-700'
          >
            <UiIcon @icon='chevron-down' @type='micro' />
            <span class='sr-only'>Open menu</span>
          </menu.Trigger>

          {{#if @rideRequest.rideId}}
            <menu.Button
              data-test-id='rider-btn'
              @href='{{this.adminConsoleUrl}}/riders/{{@rideRequest.riderId}}'
            >
              Admin Console Rider
            </menu.Button>
            <menu.Button
              data-test-id='ride-btn'
              @href='{{this.adminConsoleUrl}}/rides/{{@rideRequest.rideId}}'
            >
              Admin Console Ride
            </menu.Button>
          {{/if}}

          {{#if
            (and
              @rideRequest.rideId (notEq @rideRequest.requestStatus 'closed')
            )
          }}
            <menu.Divider />
          {{/if}}

          {{#if @rideRequest.hasMatchingRides}}
            <menu.Button
              data-test-id='link-ride-btn'
              @onClick={{set this 'isLinkRideOpen' true}}
            >
              Link to Onward Ride
            </menu.Button>
            <menu.Divider />
          {{/if}}

          {{#if (notEq @rideRequest.requestStatus 'closed')}}
            <menu.Button
              data-test-id='mark-closed-btn'
              @onClick={{set this 'isConfirmationOpen' true}}
            >
              Close Request
            </menu.Button>
          {{/if}}
        </UiMenu>
      </div>
    {{/if}}

    <LinkRideForm
      @isOpen={{this.isLinkRideOpen}}
      @onCancel={{set this 'isLinkRideOpen' false}}
      @onSaved={{this.requestSaved}}
      @rideRequest={{@rideRequest}}
    />

    <UiConfirmation
      @isOpen={{this.isConfirmationOpen}}
      @onCancel={{set this 'isConfirmationOpen' false}}
      @onConfirm={{perform this.closeRequest}}
    >
      <:title>Close Request?</:title>
      <:body>
        <p>
          Do you really want to close this request?
        </p>
      </:body>
    </UiConfirmation>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VaTools::Request::GridActions': typeof GridActionsComponent;
    }
}
